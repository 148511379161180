@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
  font-weight: 300;
}

.reportSub {
  page-break-inside: avoid;
  -webkit-print-color-adjust: exact;
}

.reportCat {
  page-break-after: always;
}

.reportRightSeperator {
  border-right-width: 5px !important;
  border-right-style: double;
  -webkit-print-color-adjust: exact;
}

.reportLeftSeperator {
  border-left-width: 5px;
}

@media print {
  .table td,
  .table th {
    background-color: inherit !important;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .reportSub {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }

  .reportRightSeperator {
    border-right-width: 5px !important;
    border-right-style: double;
    -webkit-print-color-adjust: exact;
  }

  .make-grid(sm);

  .visible-xs {
    .responsive-invisibility();
  }

  .hidden-xs {
    .responsive-visibility();
  }

  .hidden-xs.hidden-print {
    .responsive-invisibility();
  }

  .hidden-sm {
    .responsive-invisibility();
  }

  .visible-sm {
    .responsive-visibility();
  }
}

.nopadding[class*='col-'] {
  padding-left: 0;
  padding-right: 0
}

/*
 * Row with equal height columns
 * --------------------------------------------------
 */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}


